
.tracker-wrapper .disabled {
  color: #666;
  background-color: #8181810e;
}

.tracker-wrapper .reactgrid-content .rg-pane .rg-cell {
  padding: 8px 16px;
  font-size: 14px;
}

.tracker-wrapper .padding-left-lg {
  padding-left: 24px !important;
}

.tracker-wrapper .justify-content-center {
  justify-content: center;
}

.tracker-wrapper .justify-content-end {
  justify-content: flex-end;
}

.tracker-wrapper .align-items-end {
  align-items: flex-end !important;
}

.tracker-wrapper .font-bold {
  font-weight: 600;
}

.tracker-wrapper .text-red {
  color: #cf1322;
}

.tracker-wrapper .text-blue {
  color: #2797ff;
}

.tracker-wrapper .text-green {
  color: #107c41;
}

.tracker-wrapper .light-green-bg {
  background-color: #e7f2ec;
}

.tracker-wrapper .text-md {
  font-size: 14px !important;
}

.tracker-wrapper .text-lg {
  font-size: 16px !important;
}

.tracker-wrapper .rg-celleditor {
  font-size: 14px;
  padding: 8px 16px;
  border-width: 1px;
  box-shadow: 0px 0px 8px #2797ff;
}

.tracker-wrapper .rg-celleditor input {
  border: none;
}

.tracker-wrapper .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}

.tracker-wrapper .reactgrid-content .rg-pane .rg-cell-focus {
  border-color: #2797ff;
  border-width: 2px;
}
