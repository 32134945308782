* {
  scrollbar-width: thin;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*:focus {
  outline: none !important;
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d0dbff;
  border-radius: 6px;
  /* outline: 1px solid #d0dbff; */
}

html {
  font-size: 62.5%;
}

body {
  /* min-width: 1440px; */
  height: 100vh;
  margin: 0;
  /* font-family: "Roboto", sans-serif; */
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
