.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.main-content {
  position: relative;
  width: 100%;
  height: calc(100% - 65px);

  .link-back {
    position: absolute;
    top: 24px;
    left: 24px;
    font-size: 1.4rem;
  }

  .link-next {
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 1.4rem;
  }
}

/* Style common component */
.bd-input-normal {
  // margin-top: 10px;
  height: 48px;
}

.bd-input-label-normal {
  margin-top: -4px;
}

.bd-button-normal {
  height: 48px;
}

.bd-select-normal {
  height: 48px;
  padding-top: 0;
  padding-bottom: 0;
  display: table-cell;
  vertical-align: middle;
}

/* End style common component */

/* Style upload box */
.upload-box {
  margin-top: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #FFFFFF;
  border: 1px dashed #DADADA;
  box-sizing: border-box;
  border-radius: 4px;
}

.icon-upload {
  color: #D2DFE8;
  font-size: 4.4rem;
}

.upload-text-brose {
  color: #ec4847;
}

/** End style upload box */

.long-text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wrap-content-scroll {
  overflow: auto;
  max-height: calc(100vh/2);
}

.wrapper-button-progress {
  position: relative;

}

.button-progress {
  // style for button loading
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}

.btn-black {
  // padding: theme.spacing(1, 3);
  height: 48px;
  color: #fff;
  background: #2D292A !important;
  border-radius: 4px;
}

.btn-grey {
  color: #fff !important;
  background: #868E92 !important;
  border: 1px solid #868E92 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.btn-blue {
  color: #fff !important;
  background: #39A4DD !important;
  border: 1px solid #39A4DD !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.btn-white {
  color: #2D292A !important;
  background: #fff !important;
  border: 1px solid #D2DFE8 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}

.konvajs-content {
  margin: 0 auto;
}

// style google autocomplete position
.pac-container {
  z-index: 9999999 !important;
}

/********************** Zoom Toolbar ************************/
.zoom-toolbar {
  position: fixed;
  right: 37px;
  bottom: 90px;
  z-index: 10;
  border-radius: 6px;

  box-shadow: 0 5px 10px rgba(36, 35, 45, 0.3), 0 1px 3px rgba(36, 35, 45, 0.3);
  background-color: #ffffff;

  &__btn {
    width: 40px;
    height: 35px;
    line-height: 35px;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;

    & + .zoom-toolbar__btn {
      border-top: 1px solid #e0dee3;
    }
  }

  &__icon {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    position: relative;

    &--plus {
      width: 14px;
      height: 14px;
      top: -1px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #1f1d2a;
        position: absolute;
        top: 50%;
        margin-top: -1px;
      }

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #1f1d2a;
        position: absolute;
        transform: rotate(90deg);
        top: 50%;
        margin-top: -1px;
      }
    }

    &--minus {
      width: 14px;
      height: 2px;
      top: -2px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #1f1d2a;
        position: absolute;
        top: 50%;
        margin-top: -1px;
      }
    }
  }
}

.zoom-toolbar {
  &__btn {
    width: 38px;

    &--point {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__icon--original {
    width: 16px;
    height: 16px;
    top: -2px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTIgMnYzSDBWMWExIDEgMCAwIDEgMS0xaDR2MnptMTMtMmExIDEgMCAwIDEgMSAxdjRoLTJWMmgtM1Ywem0xIDE1YTEgMSAwIDAgMS0xIDFoLTR2LTJoM3YtM2gyek0xIDE2YTEgMSAwIDAgMS0xLTF2LTRoMnYzaDN2MnpNNSA0aDZhMSAxIDAgMCAxIDEgMXY2YTEgMSAwIDAgMS0xIDFINWExIDEgMCAwIDEtMS0xVjVhMSAxIDAgMCAxIDEtMXptMSAydjRoNFY2eiIvPjwvc3ZnPg==);
  }
}


/**************************************************************/
/********************** Chart Styles **************************/
$primary-dark-color: #1f1d2a;
$main-font-family: "Ubuntu";
$main-font: "Ubuntu";
$small-font: 12px;
$violet-bg: #9365ff;
$green-bg: #5cca55;
$success-color: $green-bg;
$primary-color: #3284ff;
$blue-bg: $primary-color;
$border-color: #e0dee3;
$main-border: $border-color;
$danger-color: #f90071;

.line-chart,
.pie-chart {
  background: #fff;
  padding-top: 5px;
  position: relative;
  height: 100%;
}

.chart-instance {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.highcharts-title {
  color: #2d292a!important;
  font-size: 16px!important;
  line-height: 22px!important;
  font-weight: 400!important;    
}

.tooltip-custom {
  &__title {
    color: #868e92;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-left: 3px;
    margin-top: 5px;

    & + div {
      margin-left: 3px;
    }

    &__link {
      color: $primary-dark-color;
    }
  }

  &__marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    margin-right: 5px;
  }

  &__name {
    color: #1b1a22;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;

    &--neutral {
      font-weight: normal;
    }
  }

  &__diff {
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    position: relative;
    padding-left: 12px;

    &.plus {
      color: #00b341;
      &::before {
        content: " ";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 5px solid #00b341;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
    &.minus {
      color: #ec4847;
      &::before {
        content: " ";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 5px solid #ec4847;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
  }
}

.label-custom {
  &__text {
    opacity: 0.9;
    color: #1b1a22;
    font-family: $main-font-family;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    top: -2px;
  }
}

.pie-chart {
  &__outer-label {
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 50%;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    color: #1f1d2a;
    font-size: $small-font;
    font-weight: 400;
    position: absolute;
    //left: 48%;
    top: 50%;
    transform: translateY(-50%);
    right: 0;

    /*@include breakpoint(1025px) {
      left: 47%;
    }

    @include breakpoint(1250px) {
      left: 37%;
    }*/

    &__item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 0;
      list-style: none;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__text {
      display: inline-block;
    }

    &__marker {
      flex: 1 1 20px;
      max-width: 8px;
      height: 8px;
      font-size: 0;
      line-height: 0;
      border-radius: 100%;
      display: block;
      margin-right: 8px;
      margin-left: 4px;

      &--violet {
        background-color: $violet-bg;
      }
      &--green {
        background-color: $green-bg;
      }
      &--blue {
        background-color: $blue-bg;
      }

      &--rose {
        background-color: #ff0671;
      }
      &--orange {
        background-color: #ff9b2f;
      }
    }
  }
}

.pie-label-custom {
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
}

.pie-chart-container {
  display: flex;
  height: 255px;
  position: relative;

  &__col {
    flex: 1 1 50%;
    width: 50%;
  }
}

$circle-diagram-sm: 56px;

.circle-diagram {
  position: relative;
  display: block;
  z-index: 2;

  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 4px solid #dfdde2;
    z-index: 1;
    border-radius: 50%;
  }

  &--sm {
    width: 56px;
    height: 56px;
  }

  &__percents {
    font-size: 13px;
    color: #1f1d2a;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__svg {
    position: relative;
    z-index: 2;
  }
}

.chart-container {
  &__multiple {
    display: flex;
    height: 100%;

    & > div {
      flex: 0 1 32%;
      height: 250px;
      max-width: 32%;
    }
  }

  &__text {
    position: absolute;
    top: 200px;
    color: #1f1d2a;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    padding: 0 11px;
  }
}

.chart-wrapper__title {
  color: #1b1a22;
  margin-bottom: 25px;
  font-size: 1.125rem;
  font-weight: 600;
}

.charts-block {
  display: flex;
  flex-wrap: wrap;
}

.chart-tooltip {
  padding: 10px;
  box-shadow: 0 6px 10px 0 rgba(31, 29, 42, 0.2);
  position: relative;
  border-radius: 6px;

  table td {
    padding: 0;
  }
}

/************************************** NEW *****************************************/
// Fix for charts
*::-ms-clear {
  display: none;
}

// Highcharts styles
.highcharts-tick {
  display: none;
}

/**
  Chart pending (preloader)
 */
.chart-container {
  position: relative;
}

.data-status-wrapper--circle {
  position: relative;

  .data-status {
    bottom: inherit;
    top: 50%;
    margin-top: -17px;
  }
}

.data-status {
  width: 100%;
  position: absolute;
  bottom: 25px;
  left: 0;
  text-align: center;

  &__label {
    font-weight: normal;
    font-size: 14px;
  }

  &__bar {
    height: 3px;
    width: calc(100% - 68px);
    overflow: hidden;
    margin-left: 34px;
    position: relative;
    display: block;
    background-color: #acece6;
    border-radius: 2px;
  }

  &__bar--move {
    &:before {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 3.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &:after {
      content: "";
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 3.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      animation-delay: 2.15s;
    }
  }
}

.data-status--sm {
  .data-status__bar {
    margin-left: 24px;
    width: calc(100% - 48px);
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.data-status--primary {
  .data-status__label {
    color: $primary-color;
  }

  .data-status__bar {
    background-color: #b7d4ff;

    &:before,
    &:after {
      background-color: $primary-color;
    }
  }
}

.data-status--success {
  .data-status__label {
    color: $success-color;
  }

  .data-status__bar {
    background-color: #bce4b9;

    &:before,
    &:after {
      background-color: $success-color;
    }
  }
}

.data-status--danger {
  .data-status__label {
    color: $danger-color;
  }

  .data-status__bar {
    background-color: #feb6d6;

    &:before,
    &:after {
      background-color: $danger-color;
    }
  }
}

.data-status--neutral {
  .data-status__label {
    color: #b3b4bd;
  }
}

.highcharts-label.highcharts-no-data {
  font-family: $main-font;
}
