// define font Ageo
@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 300;
  src: local('Ageo'), local('Ageo-Regular'), url(./assets/fonts/Ageo/Ageo-Light.woff) format('woff2');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 500;
  src: local('Ageo'), local('Ageo-Regular'), url(./assets/fonts/Ageo/Ageo-Medium.woff) format('woff2');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 600;
  src: local('Ageo'), local('Ageo-Regular'), url(./assets/fonts/Ageo/Ageo-SemiBold.woff) format('woff2');
}

@font-face {
  font-family: 'Ageo';
  font-style: normal;
  font-weight: 700;
  src: local('Ageo'), local('Ageo-Regular'), url(./assets/fonts/Ageo/Ageo-Bold.woff) format('woff2');
}

// end font Ageo

// define font Ubuntu
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url(./assets/fonts/Ubuntu/Ubuntu-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 400;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: url(./assets/fonts/Ubuntu/Ubuntu-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url(./assets/fonts/Ubuntu/Ubuntu-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu-Light';
  font-style: normal;
  src: url(./assets/fonts/Ubuntu/Ubuntu-Light.ttf) format('truetype');
}

// end font Ubuntu

@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/IcoMoon/icomoon.eot?d4da5b');
  src:  url('./assets/fonts/IcoMoon/icomoon.eot?d4da5b#iefix') format('embedded-opentype'),
    url('./assets/fonts/IcoMoon/icomoon.ttf?d4da5b') format('truetype'),
    url('./assets/fonts/IcoMoon/icomoon.woff?d4da5b') format('woff'),
    url('./assets/fonts/IcoMoon/icomoon.svg?d4da5b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-column:before {
  content: "\e90f";
}
.icon-combined:before {
  content: "\e910";
}
.icon-pencil-outline:before {
  content: "\e911";
}
.icon-my-location:before {
  content: "\e912";
}
.icon-lock:before {
  content: "\e913";
}
.icon-plus-bold:before {
  content: "\e914";
}
.icon-cross:before {
  content: "\e915";
}
.icon-greater:before {
  content: "\e916";
}
.icon-tick-long:before {
  content: "\e919";
}
.icon-back-long:before {
  content: "\e91b";
}
.icon-refresh:before {
  content: "\e91c";
}
.icon-heart:before {
  content: "\e91d";
}
.icon-Vector8:before {
  content: "\e91e";
}
.icon-trash:before {
  content: "\e91f";
}
.icon-question:before {
  content: "\e920";
}
.icon-calendar:before {
  content: "\e921";
}
.icon-next:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
.icon-info1:before {
  content: "\e908";
}
.icon-back:before {
  content: "\e90e";
}
.icon-plus1:before {
  content: "\e909";
}
.icon-shield:before {
  content: "\e90a";
}
.icon-start:before {
  content: "\e90b";
}
.icon-tick:before {
  content: "\e90c";
}
.icon-pencil:before {
  content: "\e90d";
}
.icon-info:before {
  content: "\e905";
}
.icon-cloud-backup-up-arrow:before {
  content: "\e904";
}
.icon-edit:before {
  content: "\e900";
}
.icon-delete:before {
  content: "\e901";
}
.icon-controls:before {
  content: "\e902";
}
.icon-plus:before {
  content: "\e903";
}

